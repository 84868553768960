<template>
  <LoginBase>
    <div>
      <FormHead
        class="pr-14"
        header="Verify your access"
        subheader=""
      />
      <validation-observer ref="form">
        <v-form>
          <div v-if="!phoneNumber">
            <div class="login-text-input-header">
              To complete your secure login, we require your to setup two-factor authentication.
              To complete this setup, please enter the mobile phone number to receive a security code
            </div>
            <v-row>
              <v-col cols="3" class="mt-6">
                <div class="login-text-input-header">
                  Phone
                </div>
              </v-col>
              <v-col cols="9">
                <CustomTelInput
                  v-model="phone"
                  placeholder="+1 (###) ###-####"
                  :value="phone"
                  :maxlength="150"
                  :required="true"
                />
              </v-col>
            </v-row>
          </div>
          <div v-else class="login-text-input-header">
            Your account is protected with two-factor authentication.
            To complete your secure log in, we will send you a single-use, six-digit security code
          </div>
          <br>
          <br>
          <div class="login-text-input-header">
            {{ phoneText }}
          </div>
        </v-form>
      </validation-observer>

      <v-btn
        dense
        max-height="34px"
        block
        color="primary"
        class="mb-3 mt-2"
        @click="confirmType(type, phone)"
      >
        Send Code
      </v-btn>
      <v-btn
        text
        block
        color="primary"
        @click="returnBack()"
      >
        Return to Login Page
      </v-btn>
    </div>
  </LoginBase>
</template>

<script>
import FormHead from "@/views/Login/components/FormHead";
import LoginBase from "@/views/Login/LoginBase";
import CustomTelInput from "@/sharedComponents/CustomTelInput";

export default {
  name: "PhoneConfirm",
  metaInfo: {
    title: 'Phone Confirm'
  },
  components: { FormHead, LoginBase, CustomTelInput },
  props: {
    token: {
      default: () => '',
      type: String
    }
  },
  data: () => ({
    type: 'sms',
    phoneText: '',
    phoneNumber: '',
    phone: null,
  }),
  async created() {
    if (localStorage.getItem("authenticated2fa") == "true"
        && this.$store.getters["user/user_info"].twoFaExpirationDate
        && new Date().getTime() < new Date(this.$store.getters["user/user_info"].twoFaExpirationDate).getTime())
    {
      await this.$router.push({name: 'Accounts'})
    }
    this.phoneNumber = this.$store.getters["user/user_info"].phone;
    if (this.phoneNumber) {
      this.phoneText = 'Send SMS to mobile number ending in ' + this.$store.getters["user/user_info"].phone.slice(-4);
    } else {
      this.phoneText = 'If you select to send the one - time code to your phone, you hereby authorize Pulse Health to\n' +
          ' deliver the one time code via SMS';
    }
  },
  methods: {
    async validate() {
      return await this.$refs.form.validate();
    },
    async confirmType(type, phone) {
      if (!(await this.validate())) {
        this.$notifier.error("Please check the form again.");
        return;
      }
      await this.$rest.user.phoneValidation({
        type: type,
        phone: phone
      });
      await this.$router.push({
        name: 'PhoneCodeConfirm',
        params: {
          type: type,
          phone: phone,
        },
      });
    },
    async returnBack() {
      await this.$rest.login.resetRememberMe();
      await this.$rest.login.logout();
      this.$store.commit('user/clearLogoutCheck');

      await this.$router.push({name: 'Login'});
    }
  }
};
</script>
<style lang="scss" scoped>
.login-text-input-header {
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  font-weight: 600;
  color: #241c15;
}

#btn-primary {
  background-color: #2b84eb !important;
}
</style>
